 import React from 'react';
 import DefaultLayout from '../../layouts/DefaultLayout';
 import TemplateColumn, { TemplateColumnWithSidebarContainer } from '../../layouts/TemplateColumn';
 import { PageHeader, PageSection, RelatedContent, CrossLink, IndicationPageIntro, PrimaryClaim, PageSubSection, AnchorLinks, SecondaryCallout, KMCurve, TextBlock } from '../../components';
 import GEJ from '../../components/StudyDesign/__study-design/gej';
 import { jobCodes } from '../../util/data/jobCodes';
 import { StaticImage } from 'gatsby-plugin-image';

 // SEO INFORMATION
 const pageMeta = {
     title: `KEYNOTE-859 - Clinical Trial Results | HCP`,
     keywords: `keynote 859, gastric adenocarcinoma clinical trials`,
     description: `Health care professionals may review findings from KEYNOTE⁠-859, a clinical trial for patients with locally advanced or metastatic HER2-negative gastric or gastroesophageal junction (GEJ) adenocarcinoma.`,
     schemaJsonLD: [
         `{"@context":"https://schema.org/","url":"https://www.keytrudahcp.com/efficacy/gastric-adenocarcinoma/","@type":"ApprovedIndication","name":"KEYTRUDA®","alternateName":"pembrolizumab","description":"KEYTRUDA, in combination with fluoropyrimidine- and platinum-containing chemotherapy, is indicated for the first-line treatment of adults with locally advanced unresectable or metastatic HER2-negative gastric or gastroesophageal junction (GEJ) adenocarcinoma."}`,
         '{"@context":"https://schema.org/","url":"https://www.keytrudahcp.com/efficacy/gastric-adenocarcinoma/","@type":"MedicalWebPage","name":"Study Design for KEYNOTE-859","description":"The efficacy of KEYTRUDA in combination with fluoropyrimidine- and platinum-containing chemotherapy was investigated in KEYNOTE-859, a Phase 3, multicenter, randomized, double-blind, placebo-controlled trial that enrolled 1,579 patients with HER2-negative advanced gastric or gastroesophageal junction (GEJ) adenocarcinoma who had not previously received systemic therapy for metastatic disease. Patients with an autoimmune disease that required systemic therapy within 2 years of treatment or a medical condition that required immunosuppression were ineligible. Randomization was stratified by PD-L1 expression (CPS ≥1 or CPS <1), chemotherapy regimen (5-FU plus cisplatin [FP] or capecitabine plus oxaliplatin [CAPOX]) and geographic region (Europe/Israel/North America/Australia, Asia, or Rest of the World)."}',
         '{"@context":"https://schema.org/","url":"https://www.keytrudahcp.com/efficacy/gastric-adenocarcinoma/","@type":"MedicalAudience","audienceType":"health care professionals","geographicArea":"This site is intended for health care professionals of the United States, its territories, and Puerto Rico."}'
     ]
 }

 //from /src/util/data/indications.js
 const indicationId = 18;
 const jobCode = jobCodes[18].jobCode;

 const relatedContent = [
     {
         section: 'Resources',
         link: {
             url: '/resources/mechanism-of-action/',
             label: 'Mechanism of Action'
         }
     },
     {
         section: 'Resources',
         link: {
             url: 'https://www.merckaccessprogram-keytruda.com/',
             label: 'Access & Reimbursement Support'
         }
     }
 ];

 const crossLinkContent = {
     sections: [
         {
             heading: "Related Information",
             items: [
                 { text: 'Selected Adverse Reactions', url: '/safety/adverse-reactions/gastric-adenocarcinoma/' },
                 { text: 'Dosing', url: '/dosing/options/' },
             ]
         },
     ],
     interestLinks: {
         heading: "You Might Be Interested In",
         items: [
             { eyebrow: 'Resources', text: 'Mechanism of Action', url: '/resources/mechanism-of-action/' },
         ],
     }
 };

 const anchorLinkModelData = [
    { label: 'Clinical Findings from KEYNOTE&#8288;-&#8288;859' }, 
    { label: 'Study Design for KEYNOTE&#8288;-&#8288;859' }, 
];

const featuredStatFootnotes = [
    {
        label: "a.",
        text: "Based on the stratified Cox proportional hazard model."
    },
    {
        label: "b.",
        text: "One-sided <i>P</i> value based on a stratified log-rank test."
    },
    {
        label: "c.",
        text: "Best objective response as confirmed complete response or partial response."
    },
    {
        label: "d.",
        text: "One-sided <i>P</i> value based on stratified Miettinen & Nurminen method."
    },
    {
        label: "e.",
        text: "Based on Kaplan-Meier estimation."
    },
];


const KMFootnotes = [
    {
        label: "f.",
        text: "Based on the stratified Cox proportional hazard model."
    },
    {
        label: "g.",
        text: "One-sided <i>P</i> value based on a stratified log-rank test."
    },
    {
        label: "h.",
        text: "Best objective response as confirmed complete response or partial response."
    },
    {
        label: "i.",
        text: "One-sided <i>P</i> value based on stratified Miettinen & Nurminen method."
    },
    {
        label: "j.",
        text: "Based on Kaplan-Meier estimation."
    },
];

const ComparisonTable3Footnotes = [
    {
        label: "k.",
        text: "Based on the stratified Cox proportional hazard model."
    },
    {
        label: "l.",
        text: "One-sided <i>P</i> value based on a stratified log-rank test."
    },
    {
        label: "m.",
        text: "Best objective response as confirmed complete response or partial response."
    },
    {
        label: "n.",
        text: "One-sided <i>P</i> value based on stratified Miettinen & Nurminen method."
    },
    {
        label: "o.",
        text: "Based on Kaplan-Meier estimation."
    },
];

 const Page = ({ location }) => {

     return (
         <DefaultLayout 
         jobCode={jobCode} 
         indicationId={indicationId} 
         pageMeta={ pageMeta } 
         location={ location }
         >
             <div data-template-name="template-e">

                 {/* Begin Intro Column */}
                 <TemplateColumn id="template-e-intro" maxWidth={824}>
                     <PageHeader title="Clinical Trial Results" />
                     <PageSection bgColor="white">
                         <IndicationPageIntro 
                            indicationId={indicationId} 
                         />
                         <AnchorLinks items={anchorLinkModelData} />
                     </PageSection>
                     <PageSection bgColor="gradient">
                      <PrimaryClaim theme="light">Superior Overall Survival with KEYTRUDA + FP or CAPOX vs FP or CAPOX alone</PrimaryClaim>
                     </PageSection>
                 </TemplateColumn>
                 {/* End Intro Column */}

                 <TemplateColumnWithSidebarContainer>
                     {/* Begin Main Column */}
                     <TemplateColumn id="template-e-main">
                        <PageSection title={anchorLinkModelData[0].label}>
                            <PageSubSection title='All Patients (N=1,579)'>
                                <TextBlock><p>The study included patients with advanced HER2-negative gastric or GEJ adenocarcinoma, with or without PD&#8288;-&#8288;L1 expression</p></TextBlock>
                                <SecondaryCallout
                                    title="22% reduction in the risk of death with KEYTRUDA + FP or CAPOX vs FP or CAPOX alone "
                                    text='HR<sup>a</sup>=0.78; 95% CI, 0.70–0.87; <i>P</i><sup>b</sup><0.0001'
                                />
                                {/* denotes ongoing response missing here */}
                                <TextBlock footnotes={featuredStatFootnotes} definitions='
                                        + Denotes ongoing response;
                                        1L = first line; 
                                        5-FU = 5-fluorouracil; 
                                        CAPOX = capecitabine plus oxaliplatin; 
                                        CI = confidence interval; 
                                        CR = complete response; 
                                        DOR = duration of response;
                                        FP = 5-FU plus cisplatin; 
                                        GEJ = gastroesophageal junction; 
                                        HER2 = human epidermal growth factor receptor 2;
                                        HR = hazard ratio; 
                                        mOS = median overall survival; 
                                        mPFS = median progression&#8288;-&#8288;free survival; 
                                        ORR = objective response rate; mDOR = median duration of response; 
                                        PD&#8288;-&#8288;L1 = programmed death ligand 1;
                                        PR = partial response.
                                    '>
                                        <p><strong>mOS</strong> in months (95% CI): </p>
                                        <ul>
                                            <li>12.9 (11.9–14.0) with KEYTRUDA + FP or CAPOX (KEYTRUDA + chemo arm) </li>
                                            <li>11.5 (10.6–12.1) with FP or CAPOX alone (placebo + chemo arm) </li>
                                            <li>Events observed:
                                            <ul> 
                                                <li>76% (n=603/790) in the KEYTRUDA + chemo arm</li>
                                                <li>84% (n=666/789) in the placebo + chemo arm</li>
                                            </ul>
                                            </li>
                                        </ul>
                                        <p><strong>mPFS</strong> in months (95% CI):</p>
                                        <ul>
                                            <li>6.9 (6.3–7.2) in the KEYTRUDA + chemo arm</li>
                                            <li>5.6 (5.5–5.7) in the placebo + chemo arm</li>
                                            <li>HR<sup>a</sup>=0.76 (95% CI, 0.67–0.85; <i>P</i><sup>b</sup>&lt;0.0001)</li>
                                            <li>Events observed: 
                                                <ul>
                                                <li>72% (n=572/790) in the KEYTRUDA + chemo arm</li>
                                                <li>77% (n=608/789) in the placebo + chemo arm</li>
                                                </ul>
                                            </li>
                                        </ul>
                                        <p><strong>ORR</strong><sup>c</sup> (95% CI; <i>P</i><sup>d</sup>&lt;0.0001): </p>
                                        <ul>
                                            <li>51% (48–55) in the KEYTRUDA + chemo arm
                                             <ul>
                                                 <li><b>PR: </b> 42%, <b>CR:</b> 9%</li>
                                             </ul>
                                            </li>
                                            <li>42% (38–45) in the placebo + chemo arm
                                             <ul>
                                                 <li><b>PR: </b> 36%, <b>CR:</b> 6%</li>
                                             </ul>
                                         </li>
                                        </ul>
                                        <p><strong>mDOR</strong><sup>e</sup> in months (95% CI):</p>
                                        <ul>
                                            <li>8.0 (7.0–9.7; range 1.2+, 41.5+) among 405 responding patients in the KEYTRUDA + chemo arm</li>
                                            <li>5.7 (5.5–6.9; range 1.3+, 34.7+) among 331 responding patients in the placebo + chemo arm</li>
                                        </ul>

                                </TextBlock>
                            </PageSubSection>
                            <PageSubSection title='PD&#8288;-&#8288;L1 CPS ≥1 Population (n=1,235)'>
                                <KMCurve
                                    title="Kaplan-Meier estimates of OS in patients with PD&#8288;-&#8288;L1 CPS ≥1"
                                    hr="HR<sup>f</sup> 0.74 (95% CI, 0.65–0.84); <i>P</i><sup>g</sup><0.0001"
                                    image={() => <StaticImage 
                                        src="../../assets/GASG233110_KN859_OS_Restricted_v6.png"
                                        placeholder="none"
                                        alt="Kaplan-Meier Estimates of Overall Survival (OS) in Patients With PD-L1 CPS ≥1 from KEYNOTE-859" />
                                    }
                                />
                                <SecondaryCallout
                                    title="26% reduction in the risk of death with KEYTRUDA + FP or CAPOX vs FP or CAPOX alone in patients with PD&#8288;-&#8288;L1 CPS ≥1"
                                />
                                <TextBlock footnotes={KMFootnotes} definitions='
                                        + Denotes ongoing response;
                                        CPS = combined positive score.
                                    '>
                                    <p><strong>mOS</strong> in months (95% CI): </p>
                                        <ul>
                                            <li>13.0 (11.6–14.2) in the KEYTRUDA + chemo arm </li>
                                            <li>11.4 (10.5–12.0) in the placebo + chemo arm </li>
                                            <li>Events observed: 
                                                <ul>
                                                    <li>75% (n=464/618) in the KEYTRUDA + chemo arm</li>
                                                    <li>85% (n=526/617) in the placebo + chemo arm</li>
                                                </ul>
                                            </li>
                                        </ul>
                                    <p><strong>mPFS</strong> in months (95% CI):</p>
                                        <ul>
                                            <li>6.9 (6.0–7.2) in the KEYTRUDA + chemo arm</li>
                                            <li>5.6 (5.4–5.7) in the placebo + chemo arm</li>
                                            <li>HR<sup>f</sup>=0.72 (95% CI, 0.63–0.82; <i>P</i><sup>g</sup>&lt;0.0001)</li>
                                            <li>Events observed: 
                                                <ul>
                                                    <li>72% (n=443/618) in the KEYTRUDA + chemo arm</li>
                                                    <li>78% (n=483/617) in the placebo + chemo arm</li>
                                                </ul>
                                            </li>
                                        </ul>
                                    <p><strong>ORR</strong><sup>h</sup> (95% CI; <i>P</i><sup>i</sup>=0.0004): </p>
                                        <ul>
                                            <li>52% (48–56) in the KEYTRUDA + chemo arm
                                             <ul>
                                                 <li><b>PR: </b> 42%, <b>CR:</b> 10%</li>
                                             </ul>
                                            </li>
                                            <li>43% (39–47) in the placebo + chemo arm
                                             <ul>
                                                 <li><b>PR: </b> 37%, <b>CR:</b> 6%</li>
                                             </ul>
                                         </li>
                                        </ul>
                                    <p><strong>mDOR</strong><sup>j</sup> in months (95% CI):</p>
                                        <ul>
                                            <li>8.3 (7.0–10.9; range 1.2+, 41.5+) among 322 responding patients in the KEYTRUDA + chemo arm</li>
                                            <li>5.6 (5.4–6.9; range 1.3+, 34.2+) among 263 responding patients in the placebo + chemo arm</li>
                                        </ul>
                                </TextBlock>
                            </PageSubSection>
                            <PageSubSection title='PD&#8288;-&#8288;L1 CPS  ≥10 Population'>
                                <TextBlock definitions='+ Denotes ongoing response.' footnotes={ComparisonTable3Footnotes}>
                                    <p><strong>mOS</strong> in months (95% CI): </p>
                                    <ul>    
                                        <li>15.7 (13.8–19.3) in the KEYTRUDA + chemo arm </li>
                                        <li>11.8 (10.3–12.7) in the placebo + chemo arm </li>
                                        <li>HR<sup>k</sup>=0.65 (95% CI, 0.53–0.79; <i>P</i><sup>l</sup>&lt;0.0001)</li>
                                        <li>Events observed: 
                                        <ul>
                                            <li>67% (n=188/279) in the KEYTRUDA + chemo arm</li>
                                            <li>83% (n=226/272) in the placebo + chemo arm</li>
                                        </ul>
                                        </li>
                                    </ul>
                                    <p><strong>mPFS</strong>  in months (95% CI):</p>
                                    <ul>
                                        <li>8.1 (6.8–8.5) in the KEYTRUDA + chemo arm</li>
                                        <li>5.6 (5.4–6.7) in the placebo + chemo arm</li>
                                        <li>HR<sup>k</sup>=0.62 (95% CI, 0.51–0.76; <i>P</i><sup>l</sup>&lt;0.0001)</li>
                                        <li>Events observed: 
                                        <ul>
                                            <li>68% (n=190/279) in the KEYTRUDA + chemo arm</li>
                                            <li>77% (n=210/272) in the placebo + chemo arm</li>
                                        </ul>
                                        </li>
                                    </ul>
                                    <p><strong>ORR</strong><sup>m</sup>  (95% CI; <i>P</i><sup>n</sup>&lt;0.0001): </p>
                                    <ul>
                                        <li>61% (55–66) in the KEYTRUDA + chemo arm
                                             <ul>
                                                 <li><b>PR: </b>48%, <b>CR:</b> 13%</li>
                                             </ul>
                                         </li>
                                        <li>43% (37–49) in the placebo + chemo arm
                                             <ul>
                                                 <li><b>PR: </b>38%, <b>CR:</b> 5%</li>
                                             </ul>
                                         </li>
                                    </ul>
                                    <p><strong>mDOR</strong><sup>o</sup> in months (95% CI):</p>
                                    <ul>
                                        <li>10.9 (8.0–13.8; range 1.2+, 41.5+) among 169 responding patients in the KEYTRUDA + chemo arm</li>
                                        <li>5.8 (5.3–7.0; range 1.4+, 31.2+) among 117 responding patients in the placebo + chemo arm</li>
                                    </ul>
                                </TextBlock>
                                
                                <TextBlock>
                                    <p><b>In an exploratory analysis in patients with PD&#8288;-&#8288;L1 CPS &lt;1 (n=344) at the time of the prespecified interim analysis of OS:</b></p>
                                    <ul>
                                        <li>mOS in months (95% CI):
                                            <ul>
                                                <li>12.7 (11.4, 15.0) in the KEYTRUDA + chemo arm</li>
                                                <li>12.2 (9.5, 14.0) in the placebo + chemo arm</li>
                                                <li>HR=0.92; 95% CI; 0.73-1.17</li>
                                            </ul>
                                        </li>
                                    </ul>
                                </TextBlock>
                           
                            </PageSubSection>
                        </PageSection>
                        {/* END PAGE SECTION */}

                        <PageSection
                            title={anchorLinkModelData[1].label}
                            bgColor='cloud'
                        >
                            <GEJ />
                        </PageSection>
                     </TemplateColumn>

                     {/* End Main Column */}
                 <TemplateColumn id="template-e-sidebar">
                         <PageSection bgColor="mist">
                             <CrossLink {...crossLinkContent} />
                         </PageSection>
                     </TemplateColumn>

                 </TemplateColumnWithSidebarContainer>

                 <TemplateColumn id="template-e-secondary">
                     <PageSection bgColor="keytrudaGreen">
                         <RelatedContent items={relatedContent} />
                     </PageSection>
                 </TemplateColumn>
             </div>
         </DefaultLayout>
     );
 };

 export default Page;
