import React from 'react';
import ComponentWrapper from '../../../components/layout/ComponentWrapper';
import { StudyCopyBlock } from '../index';

const GEJ = () => (
    
    <ComponentWrapper>
        <StudyCopyBlock 
            title="Study Overview"
            description={`
                <p>The efficacy of KEYTRUDA in combination with fluoropyrimidine- and platinum&#8288;-&#8288;containing chemotherapy was investigated in KEYNOTE&#8288;-&#8288;859⁠, a Phase 3,
                multicenter, randomized, double-blind, placebo-controlled trial that
                enrolled 1,579 patients with HER2-negative advanced gastric or
                gastroesophageal junction (GEJ) adenocarcinoma who had not
                previously received systemic therapy for metastatic disease. Patients
                with an autoimmune disease that required systemic therapy within 2
                years of treatment or a medical condition that required
                immunosuppression were ineligible. Randomization was stratified by
                PD&#8288;-&#8288;L1 expression (CPS&nbsp;≥1 or CPS&nbsp;<1), chemotherapy regimen (5-FU plus cisplatin [FP] or capecitabine plus oxaliplatin [CAPOX]) and
                geographic region (Europe/Israel/North America/Australia, Asia, or
                Rest of the World).</p>
                <p>Patients were randomized (1:1) to one of the following two treatment arms;
                treatment was administered prior to chemotherapy on Day 1 of each
                cycle:</p>
                    <ul>
                        <li>KEYTRUDA 200&nbsp;mg, investigator's choice of combination chemotherapy of cisplatin 80&nbsp;mg/m<sup>2</sup> and 5-FU 800&nbsp;mg/m<sup>2</sup>/day for 5 days (FP) or oxaliplatin 130&nbsp;mg/m<sup>2</sup> and
                        capecitabine 1,000&nbsp;mg/m<sup>2</sup> bid for 14 days (CAPOX) (n=790).</li>
                        <li>Placebo, investigator's choice of combination chemotherapy of cisplatin 80&nbsp;mg/m<sup>2</sup> and 5-FU 800&nbsp;mg/m<sup>2</sup>/day for 5
                        days (FP) or oxaliplatin 130&nbsp;mg/m<sup>2</sup> and capecitabine 1,000&nbsp;mg/m<sup>2</sup> bid for 14 days (CAPOX) (n=789).</li>
                    </ul>
                <p>All study medications, except oral capecitabine, were administered as
                an intravenous infusion for every 3-week cycle. Platinum agents could be administered for 6 or more cycles following local guidelines. Treatment with
                KEYTRUDA continued until RECIST v1.1-defined progression of disease as 
                determined by BICR, unacceptable toxicity, or a maximum
                of 24 months.</p>
                <p>The primary efficacy outcome was OS. Additional secondary
                endpoints were PFS, ORR, and DOR, as assessed by BICR according to RECIST v1.1 (modified to follow a
                maximum of 10 target lesions and a maximum of 5 target lesions per
                organ).</p>
            `}
        />
        
        <StudyCopyBlock 
            title="Patient Population"
            description={`
                <p>The study population characteristics were:</p>
                <ul>
                    <li>Median age: 62 years (range: 21 to 86)</li>
                    <li>Age 65 or older: 39%</li>
                    <li>Male: 68%</li>
                    <li>Female: 32%</li>
                    <li>Ethnicity/race:
                        <ul>
                            <li>White: 55%</li>
                            <li>Asian: 34%</li>
                            <li>Multiple: 4.6%</li>
                            <li>American Indian or Alaskan Native: 4.2%</li>
                            <li>Black: 1.3%</li>
                            <li>Native Hawaiian or other Pacific Islander: 0.2%</li>
                            <li>Not Hispanic or Latino: 76%</li>
                            <li>Hispanic or Latino: 21%</li>
                        </ul>
                    </li>
                    <li>ECOG PS
                        <ul>
                            <li>ECOG PS = 0: 37%</li>
                            <li>ECOG PS = 1: 63%</li>
                        </ul>
                    </li>
                    <li>Disease status
                        <ul>
                            <li>Metastatic disease (stage IV): 97%</li>
                            <li>Locally advanced unresectable disease: 3%</li>
                        </ul>
                    </li>
                    <li>PD-L1 expression
                        <ul>
                            <li>CPS ≥1: 78%</li>
                        </ul>
                    </li>
                    <li>MSI status:
                        <ul>
                            <li>MSI-H: 5% (n=74)</li>
                        </ul>
                    </li>
                    <li>Received CAPOX: 86%</li>
                </ul>
            `} 
        />

        <StudyCopyBlock 
            definitions={`
            BICR = blinded independent central review; bid = twice a day; ECOG PS = Eastern
            Cooperative Oncology Group performance status; MSI = microsatellite instability; MSI-H = microsatellite instability-high; RECIST v1.1 =
            Response Evaluation Criteria In Solid Tumors v1.1.            
            `} 
        />
    </ComponentWrapper>
);

export default GEJ;
